import React from 'react';
import './AylixFeatures.css';

const AylixFeatures = () => {
  return (
    <div className="features-section">
      <h2>A New Era of Data Analysis</h2>
      <div className="feature-cards">
        <div className="feature-card">
          <img src="images/custom_dashboard.jpg" alt="Customizable Dashboards" />
          <h3>Customizable Dashboards</h3>
          <p>
            We offer customizable dashboards tailored to different organizational roles, enhancing 
            user experience and data accessibility.
          </p>
        </div>
        <div className="feature-card">
          <img src="images/Automated_insights.png" alt="Automated Insights" />
          <h3>Automated Insights</h3>
          <p>
            Our platform generates automated insights, saving time and improving decision-making 
            for your organization.
          </p>
        </div>
        <div className="feature-card">
          <img src="images/Access_control-1.png" alt="Role-Based Access Control" />
          <h3>Role-Based Access Control</h3>
          <p>
            AYLIX ensures data security and privacy with role-based access control, allowing only 
            authorized users to access specific data.
          </p>
        </div>
        <div className="feature-card">
          <img src="images/Built-in-tool.jpg" alt="Built-In Search Tool" />
          <h3>Built-In Search Solution</h3>
          <p>
            Quickly find relevant data and insights with AYLIX's built-in search functionality, 
            designed for efficiency and accuracy.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AylixFeatures;
