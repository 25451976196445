import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Register.css';
import { awsInstance, geminiInstance, openaiInstance } from '../../axiosInstance';

function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // Safely access cardType from location.state
  const cardType = location.state?.cardType;

  const handleRegister = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    try {
      let response;
      if (cardType === 'Procurement') {
        response = await awsInstance.post('/register', { username, password, email });
      } else if (cardType === 'Sales') {
        response = await geminiInstance.post('/register', { username, password, email });
      } else if (cardType === 'Customer') {
        response = await openaiInstance.post('/eregister', { username, password, email });
      } else {
        throw new Error("Unknown card type");
      }

      setToken(response.data.token);
      navigate('/login', { state: { cardType, username } });
    } catch (error) {
      console.error('Registration error:', error);

      if (error.response) {
        console.error('Error response:', error.response);
        setMessage(error.response.data.detail || "Registration failed");
      } else if (error.request) {
        console.error('No response received:', error.request);
        setMessage("No response from server.");
      } else {
        console.error('Error message:', error.message);
        setMessage("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="reg-container">
      <img src="/images/Aylix Logo (30).png" className="reg-logo" alt="Aylix Logo" />
      <h2>Register</h2>
      <form onSubmit={handleRegister}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button className="reg-btn" type="submit">Register</button>
      </form>
      <p className="message">{message}</p>
    </div>
  );
}

export default Register;
