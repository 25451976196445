import React from 'react';
import './ImageSection.css'; // Create this CSS file for styling

const ImageSection = () => {
    return (
        <section className="main-image-section">
            <div className="image-container">
                <img src="images/hero.png" alt="Section Image" />
                <div className="overlay-text">
                    <h1>Explore Aylix Now</h1>
                    <p>Transform Your Data,<br>
                    </br>Transform Your Business</p>
                    <p>AI-<span className="purple-text">driven insights</span> for smarter decisions</p>
                    <div className="main-nav-container">
                <div className="gt-button-container">
                <button className="get-started-btn">Get Started</button>
                <button className="learn-more-btn">Learn More</button>
            </div>
        </div>
        </div>
        </div>
        </section>
    );
};

export default ImageSection;