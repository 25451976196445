import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import './Header.css';

const Header = () => {
    return (
        <header className="header">
            <div className="logo-header">
                <img src="/images/Aylix Logo (30).png" alt="Logo" />
            </div>
            <nav className="header-nav">
                <ul>
                    <li><Link to="/" className="header-nav-btn">Home</Link></li>
                    <li><Link to="/" className="header-nav-btn">About</Link></li>
                    <li>
                        <div className="log-button-container">
                            <button className="header-login-btn">Login</button>
                        </div>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
