import React from 'react';
import './ExperienceAylix.css';
import { FcComboChart } from "react-icons/fc";
import { ImBullhorn } from "react-icons/im";
import { HiOutlineLightBulb } from "react-icons/hi";
import { useNavigate } from 'react-router-dom'; // Only use useNavigate

const ExperienceAylix = () => {
  const navigate = useNavigate(); // Initialize navigate hook

  const handleClick = (insightType) => {
    console.log(`${insightType} clicked`);
    navigate('/login', { state: { cardType: insightType } }); // Navigate to the login page and pass cardType
  };

  return (
    <div className="experience-section">
      <h1>Unlock the Power of Data</h1>
      <p>
        Dive into the future of data-driven decision-making with Aylix. 
        Our cutting-edge AI solutions offer unparalleled insights into your organization's critical areas.<br />
        Explore our specialized dashboards and unlock the potential of your data.
      </p>

      <div className="cards-container">
        <div className="card">
          <div className="card-content">
            <FcComboChart className="card-icon" /> 
            <h3>PROCUREMENT INSIGHTS</h3>
            <p>Optimize Your Spend</p>
            <button className="open-ai-btn" onClick={() => handleClick('Procurement')}>Explore here</button>
          </div>
        </div>

        <div className="card">
          <div className="card-content">
            <ImBullhorn className="card-icon" />
            <h3>SALES DATA INSIGHTS</h3>
            <p>Boost Sales Revenue</p>
            <button className="open-ai-btn" onClick={() => handleClick('Sales')}>Explore here</button>
          </div>
        </div>

        <div className="card">
          <div className="card-content">
            <HiOutlineLightBulb className="card-icon" /> 
            <h3>CUSTOMER SUCCESS INSIGHTS</h3>
            <p>Elevate Customer Care</p>
            <button className="open-ai-btn" onClick={() => handleClick('Customer')}>Explore here </button>
          </div>
        </div>
      </div>

      <div className="logo">
        <img src="/images/Aylix Logo (30).png" alt="Logo" />
      </div>
      
      <footer className="aylix-footer">
  <p>Copyright © 2024 AYLIX LLC. All rights reserved. Empowering organizations with AI-driven insights.</p>
  <div className="footer-links">
    <a href="/privacy-policy">Privacy Policy</a>
  </div>
</footer>
    </div>
  );
};

export default ExperienceAylix;
