import React from 'react';
import Header from './Header'; // Import Header component
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-page">
      <Header />
      <main className="policy-content">
        <header className="policy-header">
          <h1 className="policy-title">Your Privacy is Our Priority</h1>
        </header>

        <section id="intro">
          <p>
            At Aylix, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your personal data when you visit our website or use our services.
          </p>
        </section>

        <section id="information-we-collect">
          <h2>Information We Collect</h2>
          <ul>
            <li>
              <strong>Personal Information:</strong> Information that can be used to identify you, such as your name, 
              email address, phone number, and postal address.
            </li>
            <li>
              <strong>Log Data:</strong> Information about your device and how you use our website, including your IP 
              address, browser type, access times, and referring websites.
            </li>
            <li>
              <strong>Cookies and Similar Technologies:</strong> We may use cookies and similar tracking technologies 
              to enhance your browsing experience and analyze website usage.
            </li>
          </ul>
        </section>

        <section id="how-we-use">
          <h2>How We Use Your Information</h2>
          <ul>
            <li>To provide and improve our services.</li>
            <li>To communicate with you and respond to your inquiries.</li>
            <li>To personalize your experience on our website.</li>
            <li>To analyze and monitor website usage and performance.</li>
            <li>To protect our rights and the rights of others.</li>
            <li>To comply with legal obligations.</li>
          </ul>
        </section>

        <section id="information-sharing">
          <h2>Information Sharing</h2>
          <ul>
            <li>
              <strong>Service Providers:</strong> We may engage third-party service providers to assist us in delivering 
              our services, and they may have access to your information.
            </li>
            <li>
              <strong>Legal Requirements:</strong> We may disclose your information when required by law, such as in 
              response to a court order or government request.
            </li>
            <li>
              <strong>Business Transfers:</strong> If we are involved in a merger, acquisition, or sale of all or a 
              portion of our assets, your information may be transferred as part of that transaction.
            </li>
          </ul>
        </section>

        <section id="data-security">
          <h2>Data Security</h2>
          <p>
            We take reasonable steps to protect your personal information from unauthorized access, use, or disclosure. 
            However, please be aware that no method of data transmission over the internet is 100% secure.
          </p>
        </section>

        <section id="children-privacy">
          <h2>Children's Privacy</h2>
          <p>
            Our website and services are not directed at individuals under the age of 13. We do not knowingly collect 
            personal information from children under 13. If you believe we have collected such information, please 
            contact us, and we will delete it.
          </p>
        </section>

        <section id="changes-policy">
          <h2>Changes To This Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time to reflect changes in our practices or for other 
            operational, legal, or regulatory reasons. The updated Privacy Policy will be posted on our website, and 
            the effective date will be revised accordingly.
          </p>
        </section>

        <section id="contact-us">
          <h2>Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at  
            <a href="mailto:info@aylix.com"> info@aylix.com</a>.
          </p>
          <p>
            By using our website or services, you consent to the terms of this Privacy Policy. If you do not agree with 
            these terms, please do not use our website or services.
          </p>
        </section>
        <footer className="policy-footer">
        <div className="logo-p">
        <img src="/images/Aylix Logo (30).png" alt="Logo" />
      </div>
      <p>Copyright © 2024 AYLIX LLC. All rights reserved. Empowering organizations with AI-driven insights.</p>
     </footer>
      </main>
    </div>
  
  );
};

export default PrivacyPolicy;
