import React from 'react';
import './AylixDashboard.css'; // Custom CSS for styling

const AylixDashboard = () => {
  return (
    <div className="dashboard-section">
      {/* Header Section */}
      <div className="header-text">
        <h1>Transforming Data into Actionable Insights</h1>
        <p>
          AYLIX empowers organizations with a generative AI solution designed for private access 
          to analyze and summarize company data. With role-based dashboards, users can effortlessly 
          navigate insights tailored to their specific needs.
        </p>
      </div>

      {/* Main Content: Image on the left, Features on the right */}
      <div className="dashboard-content">
        <img 
          src="images/lap.png" 
          alt="Dashboard Preview" 
          className="dashboard-image"
        />
        <div className="db-features">
          <div className="feature-box">
            <h3>Real-Time Insights</h3>
            <p>
              Aylix provides tailored data analysis and visualization that caters to the unique 
              needs of each organization, enabling informed decision-making.
            </p>
          </div>
          <div className="feature-box">
            <h3>Report Generation</h3>
            <p>
            Aylix simplifies report generation, saving time & effort for teams.
            These reports offer actionable insights into key performance indicators.
            </p>
          </div>
          <div className="feature-box">
            <h3>Data Security</h3>
            <p>
              With robust RBAC and data encryption, Aylix protects sensitive company information 
              and ensures compliance with industry regulations.
            </p>
          </div>
          <div className="feature-box">
            <h3>Predictive Analytics</h3>
            <p>
              Aylix leverages advanced algorithms to predict future trends and outcomes, helping 
              organizations anticipate challenges and seize opportunities.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AylixDashboard;
