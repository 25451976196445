import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Login.css';
import { awsInstance, geminiInstance, openaiInstance } from '../../axiosInstance';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const cardType = location.state?.cardType;

  const handleLogin = async () => {
    console.log(username, password);
    try {
      let response;
      if (cardType === 'Procurement') {
        response = await awsInstance.post('/login', { username, password });
      } else if (cardType === 'Sales') {
        response = await geminiInstance.post('/login', { username, password });
      } else if (cardType === 'Customer') {
        response = await openaiInstance.post('/login', { username, password });
      } else {
        throw new Error("Unknown card type");
      }
      setToken(response.data.token);
      setMessage('Login successful!');

      // Pass the username and cardType to the chatbot page
      navigate('/chatbot', { state: { cardType, username } });
    } catch (error) {
      if (error.response && error.response.data) {
        setMessage(error.response.data.detail || "Login failed");
      } else {
        setMessage("An error occurred. Please try again.");
      }
    }
  };

  const handleRegisterRedirect = () => {
    navigate('/register', { state: { cardType } }); // Pass cardType to Register page
  };

  return (
    <div className="login-container">
      <img src="/images/Aylix Logo (30).png" className="login-logo" alt="Aylix Logo" />
      <h2>Sign in</h2>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button className="log-btn" onClick={handleLogin}>Login</button>
      <p className="message">{message}</p>
      {token && <p>Token: {token}</p>}
      <div className="nav-link">
        <p>Don't have an account? <Link to="/register"onClick={handleRegisterRedirect} state={{ cardType }}>Register here</Link></p>
        <p>Forgot your password? <Link to="/forgot-password">Reset it here</Link></p>
      </div>
    </div>
  );
}

export default Login;
